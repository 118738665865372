.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.container {
  background-image: url("./assets/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;

  position: relative;
  /* padding-top: 92px; */
}

.box {

  position: absolute;
  top: 80px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.inner-box {
  background-color: #000000;
  border: 1px solid #1B1C1D;

  border-radius: 20px;
  overflow: "hidden";
  width: 70%;
  height: 100%;
}

.heading1 {
  /* UI Properties */
  color: var(---ffffff);
  text-align: center;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-weight: 500;
  font-size: 40px;
  margin-top: 20px;
}

.heading2 {
  width: 393px;
  /* UI Properties */
  color: var(---e6ceff);
  text-align: center;
  /* font: normal normal normal 24px/29px Inter; */
  letter-spacing: 0px;
  color: #E6CEFF;
  opacity: 1;
  font-weight: 500;
  font-size: 18px;
}



.takeme {
  margin-top: 40px;
  width: 264px;
  height: 62px;
  /* UI Properties */
  background: var(---d9fb51) 0% 0% no-repeat padding-box;
  background: #D9FB51 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
  color: var(---0b0b0b);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #0B0B0B;
  opacity: 1;
  z-index: 99999999999 !important;
}

.forground {
  height: calc(100vh - 10rem);
  width: 100vw;
  top: 19rem;
  /* bottom: calc(100vh - 55rem); */
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets//foreground.png");
}


.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  gap: 2.5rem;
}

/* Mobile Devices */
@media only screen and (max-width: 600px) {
  .logo-container {
    flex-direction: column; /* Stack logos vertically on smaller screens */
    gap: 1.5rem; /* Reduce gap */
    margin-top: 15px; /* Adjust margin */
  }
}

/* Tablets */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .logo-container {
    flex-direction: row; /* Horizontal layout */
    gap: 2rem; /* Slightly smaller gap */
    margin-top: 20px; /* Adjust margin */
  }
}

/* Laptops and Desktops */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .logo-container {
    gap: 2.5rem; /* Default gap */
    margin-top: 25px; /* Default margin */
  }
}

/* Large Desktops */
@media only screen and (min-width: 1440px) {
  .logo-container {
    gap: 3rem; /* Increase gap for larger screens */
    margin-top: 30px; /* Increase margin */
  }
}